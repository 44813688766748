import React from "react"
import { FaHome, FaCog, FaDatabase, FaMoneyBillAlt,FaRegHandshake, FaListUl, FaCheck, FaFileUpload, FaPhoneAlt, FaUser, FaRegBuilding, FaUserCog } from "react-icons/fa"
const user = JSON.parse(sessionStorage.getItem('user'))
let size = 15

const navigationSuperadminTop = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formconfig",
    title: "Setting",
    type: "collapse",
    icon: <FaCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagency",
        title: "Agency",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagency"
      },
      {
        id: "formpost",
        title: "Post",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/post"
      },
    ]
  },
]

const navigationSuperadmin = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formconfig",
    title: "Setting",
    type: "collapse",
    icon: <FaCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagent",
        title: "Agent NPN",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagent"
      },
    ]
  },
  {
    id: "formuser",
    title: "Users",
    type: "collapse",
    icon: <FaUserCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formuser1",
        title: "List and create",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/userform"
      },
    ]
  },
  {
    id: "salesreport",
    title: "Signature",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "cms",
        title: "URL CMS",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/cmsCreate"
      },
      {
        id: "Create",
        title: "Create",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/signatureCreate"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/signatureDelete"
      },
    ]
  },
  //Botón pago
  {
    id: "pay",
    title: "Pay",
    type: "item",
    icon: <FaMoneyBillAlt size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/pay"
  },
]

const navigatorProcessorSignatures = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "salesreport",
    title: "Signature",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "cms",
        title: "URL CMS",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/cmsCreate"
      },
      {
        id: "Create",
        title: "Create",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/signatureCreate"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/signatureDelete"
      },
    ]
  },
]



const navigationConfig =
          user?.profile === 251 ? navigationSuperadmin ://Superadmin
            user?.profile === 7 ? navigationSuperadminTop ://SuperadminTop
              user?.profile === 118 ? navigatorProcessorSignatures ://Processor Signatures
          []

export default navigationConfig
