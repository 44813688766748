import React from "react"
import classnames from "classnames"
import imgSupport from '../../../assets/img/home/support.gif'
// import ScrollToTop from "react-scroll-up"


const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT © {new Date().getFullYear()}
          {/* <a
            href="https://ticservice.org/portal"
            target="_blank"
            rel="noopener noreferrer"
          >
            TIC Service,
          </a>
          All rights reserved */}
        </span>
        <span className="float-md-right d-none d-md-block">
          <span className="align-middle">TICSign</span>{" "}
          {/* <Heart className="text-danger" size={15} /> */}
        </span>
      </p>
      <a style={{background:'#25d366'}} href="https://api.whatsapp.com/send/?phone=12102946190&text=Soporte+TICSign&type=phone_number&app_absent=0" className="float" target="_blank">
      <img style={{width:'80px',position: 'absolute',margin: '-30px -10px 0px 0px'}} src={imgSupport} />
          <i className="fa fa-whatsapp my-float"></i>
      </a>
    </footer>
  )
}

export default Footer
